import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'

/**
 * This file is set up as per recommendation of
 * https://chakra-ui.com/docs/theming/customize-theme
 */

// Global styles
import styles from './theme/styles'

// Components overrides & custom
import Input from './theme/components/input'
import Container from './theme/components/container'
import Button from './theme/components/button'
import Switch from './theme/components/switch'
import Link from './theme/components/link'
import ListIcon from './theme/components/list-icon'
import Tab from './theme/components/tab'

// Foundations
import sizes from './theme/foundations/sizes'
import colors from './theme/foundations/colors'

import '@fontsource/source-sans-pro/400.css'
import '@fontsource/source-sans-pro/700.css'

// Custom breakpoints
const breakpoints = createBreakpoints({
  sm: `374px`,
  md: `640px`,
  lg: `1024px`,
  xl: `1280px`,
  '2xl': `1536px`,
})

const theme = {
  styles,
  space: sizes,
  sizes,
  colors,
  fonts: {
    heading: 'Source Sans Pro',
    body: 'Source Sans Pro',
  },
  components: {
    Input,
    Container,
    Link,
    Button,
    Switch,
    Tab,
    ListIcon,
  },
  breakpoints,
  config: {
    initialColorMode: 'light',
  },
  textStyles: {
    h1: {
      textTransform: 'capitalize',
      marginBottom: '0.4em',
    },
  },
}

export default extendTheme(theme)
