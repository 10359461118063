import React, { useState, createContext } from 'react'

export const LoyaltyLionContext = createContext()

export function LoyaltyLionProvider({ children }) {
  const [initialised, setInitialised] = useState(false)
  const [authenticated, setAuthenticated] = useState(false)
  return (
    <LoyaltyLionContext.Provider
      value={{
        initState: [initialised, setInitialised],
        authState: [authenticated, setAuthenticated],
      }}
    >
      {children}
    </LoyaltyLionContext.Provider>
  )
}

export default LoyaltyLionContext
