exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-collections-gifts-jsx": () => import("./../../../src/pages/collections/gifts.jsx" /* webpackChunkName: "component---src-pages-collections-gifts-jsx" */),
  "component---src-pages-collections-index-jsx": () => import("./../../../src/pages/collections/index.jsx" /* webpackChunkName: "component---src-pages-collections-index-jsx" */),
  "component---src-pages-collections-shopify-collection-handle-index-jsx": () => import("./../../../src/pages/collections/{ShopifyCollection.handle}/index.jsx" /* webpackChunkName: "component---src-pages-collections-shopify-collection-handle-index-jsx" */),
  "component---src-pages-confirmation-jsx": () => import("./../../../src/pages/confirmation.jsx" /* webpackChunkName: "component---src-pages-confirmation-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-newsletter-jsx": () => import("./../../../src/pages/newsletter.jsx" /* webpackChunkName: "component---src-pages-newsletter-jsx" */),
  "component---src-pages-newsletter-test-jsx": () => import("./../../../src/pages/newsletter-test.jsx" /* webpackChunkName: "component---src-pages-newsletter-test-jsx" */),
  "component---src-pages-press-enquiries-jsx": () => import("./../../../src/pages/press-enquiries.jsx" /* webpackChunkName: "component---src-pages-press-enquiries-jsx" */),
  "component---src-pages-products-create-a-gift-box-jsx": () => import("./../../../src/pages/products/create-a-gift-box.jsx" /* webpackChunkName: "component---src-pages-products-create-a-gift-box-jsx" */),
  "component---src-pages-products-index-jsx": () => import("./../../../src/pages/products/index.jsx" /* webpackChunkName: "component---src-pages-products-index-jsx" */),
  "component---src-pages-rewards-jsx": () => import("./../../../src/pages/rewards.jsx" /* webpackChunkName: "component---src-pages-rewards-jsx" */),
  "component---src-pages-shopify-page-handle-jsx": () => import("./../../../src/pages/{ShopifyPage.handle}.jsx" /* webpackChunkName: "component---src-pages-shopify-page-handle-jsx" */),
  "component---src-pages-stockists-jsx": () => import("./../../../src/pages/stockists.jsx" /* webpackChunkName: "component---src-pages-stockists-jsx" */),
  "component---src-pages-trade-wholesale-jsx": () => import("./../../../src/pages/trade-wholesale.jsx" /* webpackChunkName: "component---src-pages-trade-wholesale-jsx" */),
  "component---src-templates-account-tsx": () => import("./../../../src/templates/account.tsx" /* webpackChunkName: "component---src-templates-account-tsx" */),
  "component---src-templates-article-jsx": () => import("./../../../src/templates/article.jsx" /* webpackChunkName: "component---src-templates-article-jsx" */),
  "component---src-templates-blog-list-template-jsx": () => import("./../../../src/templates/blog-list-template.jsx" /* webpackChunkName: "component---src-templates-blog-list-template-jsx" */),
  "component---src-templates-discount-tsx": () => import("./../../../src/templates/discount.tsx" /* webpackChunkName: "component---src-templates-discount-tsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-working-article-jsx": () => import("./../../../src/templates/workingArticle.jsx" /* webpackChunkName: "component---src-templates-working-article-jsx" */)
}

