const Tab = {
  baseStyle: {
    '&:focus': {
      boxShadow: `md`,
    },
    '[data-focus]': {
      boxShadow: `md`,
      '&:focus': {
        boxShadow: `md`,
      },
    },
  },
}

export default Tab
