import React, { useState } from 'react'

const ContactContext = React.createContext()

export function ContactProvider({ children }) {
  const [loading, setLoading] = useState(false)
  return (
    <ContactContext.Provider value={[loading, setLoading]}>
      {children}
    </ContactContext.Provider>
  )
}

export default ContactContext
