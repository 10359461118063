import { theme } from '@chakra-ui/react'

const defaultColors = theme.colors

const colors = {
  brand: {
    newbrown: {
      50: '#726666',
      100: '#685c5c',
      200: '#5e5252',
      300: '#544848',
      400: '#4a3e3e',
      500: '#403434',
      600: '#362a2a',
      700: '#2c2020',
      800: '#221616',
      900: '#180c0c',
    },
    brown: {
      //728c
      50: '#E9D6C3',
      100: '#E3CBB5',
      200: '#DEC1A6',
      300: '#D8B797',
      400: '#D3AC88',
      500: '#CDA177',
      600: '#C8976A',
      700: '#C28D5B',
      800: '#BD834C',
      900: '#B37942',
    },
    steak: {
      //Steak and Kidney Pie
      50: '#DDA969',
      100: '#D99F59',
      200: '#D59648',
      300: '#D28C37',
      400: '#C8822D',
      500: '#B77729',
      600: '#A66C26',
      700: '#966222',
      800: '#85571E',
      900: '#744C1A',
    },
    tan: {
      //Steak and stilton
      50: '#EED9BE',
      100: '#EAD0AE',
      200: '#E6C79E',
      300: '#E2BD8D',
      400: '#DEB47D',
      500: '#D6A461',
      600: '#D5A15D',
      700: '#D1984D',
      800: '#CD8E3C',
      900: '#C38432',
    },
    taupe: {
      //481c
      50: '#F8F4F1',
      100: '#F1EAE4',
      200: '#F6E5CB',
      300: '#EADFD6',
      400: '#E4D5C9',
      500: '#D3BBA8',
      600: '#CFB5A0',
      700: '#C8AB92',
      800: '#C1A085',
      900: '#BB9677',
      1000: '#A67B58',
      1100: '#986D49',
    },
    lightbrown: {
      //155c
      50: '#FCF6EE',
      100: '#F9EEDC',
      200: '#F6E5CB',
      300: '#F3DDB9',
      400: '#EFD19F',
      500: '#EDCC96',
      600: '#EAC385',
      700: '#E7BB73',
      800: '#E4B262',
      900: '#E1AA51',
    },
    midbrown: {
      //7508c
      50: '#F7EDDE',
      100: '#F3E4CD',
      200: '#EFDABD',
      300: '#ECD1AC',
      400: '#E8C89C',
      500: '#EDCC96',
      600: '#E0B67B',
      700: '#DCAD6A',
      800: '#D8A45A',
      900: '#D49A49',
    },
    chicken: {
      //Chicken ham leek
      50: '#EDDFD4',
      100: '#E7D5C5',
      200: '#E1CAB7',
      300: '#DBBFA9',
      400: '#D5B59A',
      500: '#CDA788',
      600: '#7F6E4D',
      700: '#5F533A',
      800: '#403726',
      900: '#201C13',
    },
    ecru: {
      //Chicken ham mushroom
      50: '#D2CBB1',
      100: '#CBC2A4',
      200: '#C3B997',
      300: '#BCB08A',
      400: '#B5A87D',
      500: '#A89968',
      600: '#A69664',
      700: '#9B8C59',
      800: '#8E8052',
      900: '#82754A',
    },
    green: {
      // Roasted med veg
      50: '#7BCA53',
      100: '#6FC544',
      200: '#65BB3A',
      300: '#5CAC35',
      400: '#549C30',
      500: '#4C8D2B',
      600: '#437D26',
      700: '#3B6D22',
      800: '#325E1D',
      900: '#2A4E18',
    },
    red: {
      // Steak and Ale Pie
      50: '#F33F63',
      100: '#F22C54',
      200: '#F01944',
      300: '#E60F3A',
      400: '#D30D35',
      500: '#BA0C2F',
      600: '#AD0B2B',
      700: '#990A27',
      800: '#860922',
      900: '#73071D',
    },
    deepred: {
      // Red Onion
      50: '#DA6C6C',
      100: '#D65C5C',
      200: '#D24B4B',
      300: '#CE3B3B',
      400: '#C43131',
      500: '#A72B2A',
      600: '#A32929',
      700: '#932525',
      800: '#832121',
      900: '#721D1D',
    },
    gray: {
      // Game pie
      50: '#D6D3DA',
      100: '#CBC7D1',
      200: '#C1BCC7',
      300: '#B7B1BE',
      400: '#ACA6B5',
      500: '#9991A4',
      600: '#9790A2',
      700: '#8D8599',
      800: '#837A90',
      900: '#796F85',
    },
    tawny: {
      // lorraine squiche
      50: '#FF9747',
      100: '#FF8B33',
      200: '#FF801F',
      300: '#FF740A',
      400: '#F56A00',
      500: '#D45D00',
      600: '#9790A2',
      700: '#8D8599',
      800: '#837A90',
      900: '#796F85',
    },
    darkgray: {
      // dark
      50: '#9A8984',
      100: '#917E78',
      200: '#87746E',
      300: '#7B6A65',
      400: '#70615C',
      500: '#5E514D',
      600: '#5A4D49',
      700: '#4F4440',
      800: '#433A37',
      900: '#38302E',
    },
    oldgold: {
      // Squash and feta
      50: '#FFEB33',
      100: '#FFE91F',
      200: '#FFE70A',
      300: '#F5DC00',
      400: '#E0CA00',
      500: '#C4B000',
      600: '#B8A500',
      700: '#A39300',
      800: '#8F8100',
      900: '#7A6E00',
    },
    cheese: {
      // Cheese and onion
      50: '#FFD35C',
      100: '#FFCE47',
      200: '#FFC933',
      300: '#FFC31F',
      400: '#FFBE0A',
      500: '#EAAA00',
      600: '#E0A500',
      700: '#CC9600',
      800: '#B88700',
      900: '#A37800',
    },
    asparagus: {
      // asparagus
      50: '#D3D0C5',
      100: '#CAC6B9',
      200: '#C2BDAE',
      300: '#B9B3A2',
      400: '#B0AA96',
      500: '#A69F88',
      600: '#9F977F',
      700: '#968E73',
      800: '#8C8469',
      900: '#807960',
    },
    purple: {
      // Venison
      50: '#8C50B9',
      100: '#8246AF',
      200: '#7740A0',
      300: '#6C3A92',
      400: '#613483',
      500: '#562F75',
      600: '#4A2966',
      700: '#402358',
      800: '#351D49',
      900: '#2B173B',
    },
    orange: {
      //Chip shop curry
      50: '#FFCA85',
      100: '#FFC170',
      200: '#FFB85C',
      300: '#FFAF47',
      400: '#FFA733',
      500: '#FF9E1B',
      600: '#FF950A',
      700: '#F58B00',
      800: '#E07F00',
      900: '#CC7400',
    },
    palepink: {
      //lamb, redcurrent
      50: '#D9BFC3',
      100: '#D1B3B7',
      200: '#C9A6AB',
      300: '#C2999E',
      400: '#BA8C92',
      500: '#B07C83',
      600: '#AB737A',
      700: '#A3666E',
      800: '#995C64',
      900: '#8C545C',
    },
    dark_gray: {
      50: '#827c7e',
      100: '#787274',
      200: '#6e686a',
      300: '#645e60',
      400: '#5a5456',
      500: '#504a4c',
      600: '#464042',
      700: '#3c3638',
      800: '#322c2e',
      900: '#282224',
    },
    dark_darkbrown: {
      50: '#696360',
      100: '#5f5956',
      200: '#554f4c',
      300: '#4b4542',
      400: '#413b38',
      500: '#37312e',
      600: '#2d2724',
      700: '#231d1a',
      800: '#191310',
      900: '#0f0906',
    },
    dark_brown: {
      50: '#d7c5ac',
      100: '#cdbba2',
      200: '#c3b198',
      300: '#b9a78e',
      400: '#af9d84',
      500: '#a5937a',
      600: '#9b8970',
      700: '#917f66',
      800: '#87755c',
      900: '#7d6b52',
    },
    dark_gold: {
      50: '#fff932',
      100: '#ffef28',
      200: '#ffe51e',
      300: '#ffdb14',
      400: '#ffd10a',
      500: '#fec700',
      600: '#f4bd00',
      700: '#eab300',
      800: '#e0a900',
      900: '#d69f00',
    },
    dark_black: {
      50: '#70686a',
      100: '#665e60',
      200: '#5c5456',
      300: '#524a4c',
      400: '#484042',
      500: '#3e3638',
      600: '#342c2e',
      700: '#2a2224',
      800: '#20181a',
      900: '#160e10',
    },
    dark_lightgray: {
      50: '#a08c90',
      100: '#968286',
      200: '#8c787c',
      300: '#826e72',
      400: '#786468',
      500: '#6e5a5e',
      600: '#645054',
      700: '#5a464a',
      800: '#503c40',
      900: '#463236',
    },
    chorizo: {
      500: '#6e1c1e',
    },
    trout: {
      500: '#5d863c',
    },
    mozzarella: {
      500: '#ae2658',
    },
    southern_fried: {
      500: '#df542f',
    },
    fish: {
      500: '#5d7e90',
    },
    egg: {
      500: '#dd923b',
    },
    black_pudding: {
      500: '#977d96',
    },
    lamb_rosemary: {
      500: '#9d7071',
    },
    steak_stilton: {
      500: '#c79962',
    },
    loyalty_orange: {
      500: '#d18243',
    },
    loyalty_green: {
      500: '#66935a',
    },
    loyalty_yellow: {
      500: '#fcc843',
    },
  },

  light: {
    color: defaultColors.gray['800'],
    bg: '#ffffff',
    primaryColorScheme: '#403434', // Used for all "colorScheme" props on components. Not defined in "dark" as components handle dark mode by themselves.
    primary: defaultColors.whiteAlpha['100'],
  },
  dark: {
    color: defaultColors.whiteAlpha['900'],
    bg: '#23201f',
    primary: defaultColors.whiteAlpha['900'],
    button: '#BA8C92',
  },
}

export default colors
