
import React, {useState, useContext, createContext, useEffect} from 'react'
import ShopifyClient from 'shopify-buy'

import { useDisclosure } from '@chakra-ui/react'
import cookie from 'js-cookie'

const client = ShopifyClient.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL,
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
  }
)


interface InitialStore {
  shopifyClient: ShopifyClient
  isAdding: boolean
  cartIsOpen: boolean
  navIsOpen: boolean
  page: undefined
  orders: any[]
  customerEmail: string | undefined
  // customerName: string | undefined
  customerToken: string | undefined
  checkout: Checkout
}

const defaultValues = {
  cart: [],
  isOpen: false,
  loading: false,
  onOpen: () => {},
  onClose: () => {},
  isOpenFloating: false,
  onOpenFloating: () => {},
  onCloseFloating: () => {},
  addVariantToCart: () => {},
  removeLineItem: () => {},
  fetchProduct: () => {},
  updateLineItem: () => {},
  customerEmail: undefined,
  // customerName: undefined,
  customerToken: undefined,
  orders: [],
  client,
  checkout: {
    lineItems: [],
    note: ""
  }
}


export const StoreContext = createContext({store: defaultValues, setStore: () => null})

const isBrowser = typeof window !== `undefined`
const localStorageKey = `shopify_checkout_id`
const previousCheckoutStorageKey = `previous_shopify_checkout_id`

export const StoreProvider = ({ children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { 
    isOpen: isOpenFloating, 
    onOpen: onOpenFloating, 
    onClose: onCloseFloating 
} = useDisclosure()
  const [checkout, setCheckout] = useState(defaultValues.checkout)
  const [loading, setLoading] = useState(false)
  const [store, setStore] = useState(defaultValues)
  const [initStore, setInitStore] = useState(false)

  const setCheckoutItem = (checkout: React.SetStateAction<{ lineItems: never[]; note: string }> | ShopifyClient.Checkout) => {
    if (isBrowser) {
      localStorage.setItem(localStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  const setPreviousCheckoutItem = (checkout: string | React.SetStateAction<{ lineItems: never[]; note: string }>) => {
    if (isBrowser) {
      localStorage.setItem(previousCheckoutStorageKey, checkout.id)
    }

    setCheckout(checkout)
  }

  useEffect(() => {
    if (initStore === false) {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser
        ? localStorage.getItem(localStorageKey)
        : null

        if (existingCheckoutID && existingCheckoutID !== `null`) {
          try {
            const existingCheckout = await client.checkout.fetch(
              existingCheckoutID
            )
       
            if (!existingCheckout.completedAt) {
              if(existingCheckout.lineItems.length > 0){
                existingCheckout.lineItems.forEach(item => {
                  if(!item.variant.available){
                    removeLineItem(existingCheckout.id, lineItem.id)
                  }
                })
              }
              setCheckoutItem(existingCheckout)
              return
            } else {
              setPreviousCheckoutItem(previousCheckoutStorageKey)
            }
          } catch (e) {
            localStorage.setItem(localStorageKey, null)
          }
        }

      const newCheckout = await client.checkout.create()
      setCheckoutItem(newCheckout)
    }
    initCustomer(setStore)
    initializeCheckout()
    setInitStore(true)
  }
  }, [])

  const addNoteToCart = (note:string) => {
    setLoading(true)
    const checkoutID = checkout.id
    const input = 
      {
        note: note.substring(0, 50),
      }
    
    try {
      return client.checkout
        .updateAttributes(checkoutID, input )
        .then((res) => {
          setCheckout(res)
          setLoading(false)
        })
    } catch (e) {
      console.log('update note error: ' + note)
      setLoading(false)
    }
  }

  const removeNCustomAttributesFromCart = (key:string) => {
    setLoading(true)
    const checkoutID = checkout.id
    const input = {customAttributes: [{key: key, value: ''}]};
    try {
      return client.checkout.updateAttributes(checkoutID, input)
        .then((res) => {
          setCheckout(res)
          setLoading(false)
        })
    } catch (e) {
      console.log('remove custom attributes error: ' + key)
      setLoading(false)
    }
  }

  const addNCustomAttributesToCart = (key:string, value:string) => {
    setLoading(true)
    const checkoutID = checkout.id
    const input = {customAttributes: [{key: key, value: value}]};
    try {
      return client.checkout
        .updateAttributes(checkoutID, input )
        .then((res) => {
          setCheckout(res)
          setLoading(false)

        })
    } catch (e) {
      console.log('update custom attributes error: ' + key)
      setLoading(false)
    }
  }


  const addVariantToCart = (variantId: any, quantity: string) => {
    setLoading(true)

    const checkoutID = checkout.id

    const lineItemsToUpdate = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ]

    try {
      return client.checkout
        .addLineItems(checkoutID, lineItemsToUpdate)
        .then((res) => {
          setCheckout(res)
          // fetchProduct(variantId).then((product) => {
          //   console.log(product)
          // })
          setLoading(false)
        })
    } catch (e) {
      console.log('addLineitem: ' + e)
      setLoading(false)
    }
  }

  const fetchProduct = (lineItemId: string) => {
    return client.product.fetch(lineItemId).then((product) => {
      return product
    })
  }

  const removeLineItem = (checkoutID: string, lineItemID: string) => {
    setLoading(true)

    return client.checkout
      .removeLineItems(checkoutID, [lineItemID])
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  const updateLineItem = (checkoutID: string, lineItemID: any, quantity: string) => {
    setLoading(true)

    const lineItemsToUpdate = [
      { id: lineItemID, quantity: parseInt(quantity, 10) },
    ]

    return client.checkout
      .updateLineItems(checkoutID, lineItemsToUpdate)
      .then((res) => {
        setCheckout(res)
        setLoading(false)
      })
  }

  return (
    <StoreContext.Provider
      value={{
        ...defaultValues,
        addVariantToCart,
        addNCustomAttributesToCart,
        removeNCustomAttributesFromCart,
        removeLineItem,
        fetchProduct,
        addNoteToCart,
        updateLineItem,
        checkout,
        loading,
        isOpen,
        onOpen,
        onClose,
        isOpenFloating,
        onOpenFloating,
        onCloseFloating,
        store,
        setStore
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

const initCustomer = (setStore: any) => {
  const customerEmail = cookie.get('customer_email')
  const customerToken = cookie.get('customer_token')
  // const customerName = cookie.get('customer_firstName')

  if (customerEmail && customerToken) {
    setStore((prevState: InitialStore) => {
      return { ...prevState, customerEmail, customerToken }
    })
  }
}

const setCustomerInState = () => {
  const {
    setStore
  }: { setStore: any } = useContext(StoreContext)

  async function updateCustomerInState() {
    const customerEmail = cookie.get('customer_email')
    const customerToken = cookie.get('customer_token')
    // const customerName = cookie.get('customer_firstName')
    setStore((prevState: InitialStore) => {
      return { ...prevState, customerEmail, customerToken }
    })
    
  }

  return updateCustomerInState
}

function useStore() {
  const { store } = useContext(StoreContext)
  return store
}

export {
  setCustomerInState,
  useStore,
}