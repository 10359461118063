const Link = {
  variants: {
    inline: {
      textDecoration: 'underline',
      _hover: {
        color: 'black',
      },
      _dark: {
        _hover: {
          color: 'whiteAlpha.700',
        },
      },
    },
  },
}

export default Link
