import * as React from 'react'
import { StoreProvider } from './src/context/store-context'
import { ContactProvider } from './src/context/contact-context'
import { UserProvider } from './src/context/user-context'
import { LoyaltyLionProvider } from './src/context/loyaltylion-context'

// export { wrapPageElement } from "./src/components/tagmanager"

export const wrapRootElement = ({ element }) => (
  <StoreProvider>
    <ContactProvider>
      <UserProvider>
        <LoyaltyLionProvider>{element}</LoyaltyLionProvider>
      </UserProvider>
    </ContactProvider>
  </StoreProvider>
)
