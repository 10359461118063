const Button = {
  baseStyle: {
    borderRadius: '0',
    paddingLeft: '0.75rem',
    paddingRight: '0.75rem',
    _focus: {
      outlineColor: '#F1EAE4',
    },
  },
  variants: {
    underline: {
      textDecoration: 'underline',
      _hover: {
        color: 'black',
      },
    },
    outline: {
      borderWidth: '2px',
      fontWeight: 'bold',
      _hover: {
        borderWidth: '2px',
      },
    },
    text: {
      fontWeight: '300',
      backgroundColor: 'transparent',
      _hover: {
        backgroundColor: 'transparent',
      },
      _focus: {
        outline: 'none',
      },
    },
  },
}

export default Button
