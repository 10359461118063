import React, { useState } from 'react'

const UserContext = React.createContext()

export function UserProvider({ children }) {
  const [shownHelper, setShownHelper] = useState(false)

  return (
    <UserContext.Provider value={[shownHelper, setShownHelper]}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContext
