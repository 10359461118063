const Input = {
  variants: {
    filled: {
      field: {
        _placeholder: {
          color: '#000',
        },
        _dark: {
          color: '#fff',
          bg: 'brand.gray.900',
          _placeholder: {
            color: '#fff',
          },
        },
      },
    },
  },
}

export default Input
